export default function LoadingBar() {
  return (
    <div 
      style={{ 
        position: 'fixed', 
        top: 0, 
        left: 0, 
        right: 0, 
        zIndex: 9999,
        height: '4px',
        backgroundColor: '#e0e0e0',
        overflow: 'hidden'
      }}
    >
      <div
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: '#007bff',
          animation: 'progressAnimation 1s infinite linear',
          transformOrigin: '0% 50%'
        }}
      />
      <style>
        {`
          @keyframes progressAnimation {
            0% {
              transform: translateX(-100%);
            }
            100% {
              transform: translateX(100%);
            }
          }
        `}
      </style>
    </div>
  );
} 