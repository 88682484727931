import React, { useEffect, useState, useRef, useCallback } from "react";
import { ButtonGroup, Button, Spinner } from "react-bootstrap";
import { VariableSizeList as List } from "react-window";

function ExcelViewer({ filename }) {
  const [tables, setTables] = useState([]);
  const [sheetNames, setSheetNames] = useState([]);
  const [currentSheet, setCurrentSheet] = useState("");
  const [rowData, setRowData] = useState([]);
  const [colHeaders, setColHeaders] = useState([]);
  const [colGroupWidths, setColGroupWidths] = useState([]);
  const rowHeightsRef = useRef([]);
  const scrollOffsetRef = useRef(0);
  const rootRef = useRef(null);
  const [divHeight, setDivHeight] = useState(800);
  const isUpDirection = useRef(false);
  const listRef = useRef({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (rootRef.current) {
      setDivHeight(rootRef.current.clientHeight); // get the height of main container
    }
  }, []);

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const domain = window.location.hostname;
        const response = await fetch(`https://${domain}/${filename}.html`);
        if (response.ok) {
          const text = await response.text();
          const parser = new DOMParser();
          const doc = parser.parseFromString(text, "text/html");

          // Get the tables
          const allTables = Array.from(doc.querySelectorAll("table"));
          setTables(allTables);

          // Extract sheet names
          const links = Array.from(doc.querySelectorAll('a[href^="#table"]'));
          const names = links.map((link) => ({
            id: link.getAttribute("href").substring(1),
            name: link.textContent,
          }));
          setSheetNames(names);
          if (names.length > 0) {
            setCurrentSheet(names[0].id);
          }

          // Parse the current table's row and column data
          if (allTables.length > 0) {
            parseTable(allTables[0]);
          }
        } else {
          console.error("Failed to fetch HTML content");
        }
      } catch (error) {
        console.error("Error fetching HTML content:", error);
      } finally {
        setIsLoading(false); // Stop loading once data is fetched
      }
    };

    fetchContent();
  }, [filename]);

  const parseTable = (table) => {
    const rows = Array.from(table.querySelectorAll("tr"));
    const colGroupElements = Array.from(table.querySelectorAll("colgroup"));

    // Extract colgroup widths with handling for spans
    const widths = [];
    colGroupElements.forEach((colGroup) => {
      const colWidth = colGroup.getAttribute("width");
      const colSpan = colGroup.getAttribute("span")
        ? parseInt(colGroup.getAttribute("span"))
        : 1;

      for (let i = 0; i < colSpan; i++) {
        widths.push(colWidth); // Repeat width for the span
      }
    });
    setColGroupWidths(widths);

    // Generate headers (A, B, C... AA, AB...)
    const headerCount = widths.length;
    const headers = Array.from({ length: headerCount }, (_, index) => ({
      text: getColumnLabel(index), // Spreadsheet-style labels
      style: "", // You can modify this to extract specific styles if needed
    }));
    setColHeaders(headers);

    const rowData = rows.slice(0).map((row) =>
      Array.from(row.querySelectorAll("td")).map((td) => ({
        element: td.outerHTML, // Get the entire td element
        style: td.getAttribute("style") || "", // Extract inline styles
        attributes: Array.from(td.attributes).reduce((acc, attr) => {
          if (attr.name !== "style") {
            // Exclude the 'style' attribute
            acc[attr.name] = attr.value;
          }
          return acc;
        }, {}),
      }))
    );
    setRowData(rowData);
  };

  const Row = React.memo(({ index, style }) => {
    const rowRef = useRef(null);
    let addedColSpan = 0;

    // Effect to measure row height after rendering
    useEffect(() => {
      if (rowRef.current) {
        setRowHeight(index, rowRef.current.clientHeight); // get height for every item
      }
    }, [rowRef]);

    function setRowHeight(index, size) {
      listRef.current.resetAfterIndex(0);
      rowHeightsRef.current = { ...rowHeightsRef.current, [index]: size };
    }

    const calculateTdWidth = (td, colIndex) => {
      const colspan = parseInt(td.attributes.colspan) || 1;
      let totalWidth = 0;

      // Sum up widths of the next 'colspan' number of columns
      for (let i = 0; i < colspan; i++) {
        totalWidth += parseInt(colGroupWidths[addedColSpan + i] || 0, 10); // Ensure fallback to 0
      }
      addedColSpan += colspan;
      return totalWidth;
    };

    const { width, ...styleWithoutWidthandTop } = style;

    return (
      <div style={{ ...styleWithoutWidthandTop }}>
        <colgroup>
          <col width="61" />
          {colGroupWidths.map((width, index) => (
            <col key={index} width={width} />
          ))}
        </colgroup>
        <tr
          style={{
            border: "1px solid rgba(0,0,0,0.1)",
            display: "inline-flex",
            width: `100%`,
          }}
          ref={rowRef}
        >
          <td
            style={{
              width: 61,
              backgroundColor: "#f0f0f0",
              textAlign: "center",
              borderRight: "1px solid rgba(0,0,0,0.1)",
              position: "sticky",
              left: "0",
              zIndex: 2,
            }}
          >
            {index + 1} {/* Row index */}
          </td>
          {rowData[index].map((cell, colIndex) => (
            <td
              key={colIndex}
              style={{
                borderRight: "1px solid rgba(0,0,0,0.1)",
                width: `${calculateTdWidth(cell, colIndex)}px`, // Dynamically set width based on colspan
                overflow: "auto",
                ...inlineStyleParser(cell.style),
              }} // Apply inline styles
              {...cell.attributes} // Apply all attributes dynamically
              dangerouslySetInnerHTML={{ __html: cell.element }} // Render the td content
            />
          ))}
        </tr>
      </div>
    );
  });

  const handleScroll = ({ scrollDirection }) => {
    if (scrollDirection === "forward") {
      isUpDirection.current = true;
    } else {
      isUpDirection.current = false;
    }
  };

  const getItemSize = useCallback((index) => {
    return rowHeightsRef.current[index] || 30; // Default to 20px if height is not measured yet
  }, []);

  // A helper function to convert inline style string to a style object
  const inlineStyleParser = (styleString) => {
    if (!styleString) return {};
    return styleString.split(";").reduce((styleObj, style) => {
      const [key, value] = style.split(":").map((str) => str.trim());
      if (key && value) {
        styleObj[camelize(key)] = value;
      }
      return styleObj;
    }, {});
  };

  // Helper function to camelize CSS property names
  const camelize = (str) => str.replace(/-./g, (m) => m[1].toUpperCase());

  const getColumnLabel = (index) => {
    let label = "";
    index += 1; // Start from 1 for easier calculation
    while (index > 0) {
      const mod = (index - 1) % 26;
      label = String.fromCharCode(mod + 65) + label; // A-Z
      index = Math.floor((index - mod) / 26);
    }
    return label;
  };

  const handleSheetChange = (sheetId) => {
    setCurrentSheet(sheetId);
    const table = tables.find((t) => {
      const sheetAnchor = t.previousElementSibling;
      return sheetAnchor && sheetAnchor.getAttribute("name") === sheetId;
    });
    if (table) {
      parseTable(table);
    }
  };

  return (
    <>
      {isLoading ? (
        <div className="loading-container">
          <Spinner animation="border" variant="dark">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <>
          <table
            className="table-wrapper"
            onScroll={handleScroll}
            ref={rootRef}
          >
            <>
              <colgroup>
                <col width="61" />
                {colGroupWidths.map((width, index) => (
                  <col key={index} width={width} />
                ))}
              </colgroup>
              <tr
                style={{
                  fontWeight: "bold",
                  backgroundColor: "#f0f0f0",
                  display: "inline-flex",
                  width: `100%`,
                }}
                className="thead"
              >
                <th style={{ textAlign: "center", width: "61px" }}>#</th>
                {colHeaders.map((header, index) => (
                  <th
                    key={index}
                    style={{
                      textAlign: "center",
                      width: `${colGroupWidths[index]}px` || "auto", // Width of corresponding column
                    }}
                  >
                    {header.text}
                  </th>
                ))}
              </tr>
            </>
            {/* Virtualized list inside the table body */}
            <List
              ref={listRef}
              height={divHeight} // Set height for scrollable area
              itemCount={rowData.length}
              itemSize={getItemSize}
              width={"100%"}
              onScroll={handleScroll}
              onItemsRendered={() => rowHeightsRef.current}
            >
              {Row}
            </List>
          </table>

          <ButtonGroup className="sheet-button-group">
            {sheetNames.map((sheet) => (
              <Button
                key={sheet.id}
                variant={currentSheet === sheet.id ? "primary" : "secondary"}
                onClick={() => handleSheetChange(sheet.id)}
              >
                {sheet.name}
              </Button>
            ))}
          </ButtonGroup>

          <style jsx>{`
            .table-wrapper {
              border: 1px solid rgba(0, 0, 0, 0.1);
              height: 2000px;
              overflow-x: auto; /* Allow scrolling */
              position: relative;
              border-collapse: collapse;
            }
            .thead th {
              position: sticky;
              top: 0;
              z-index: 1;
              background: #f0f0f0; /* Sticky header background */
            }

            td {
              text-wrap: nowrap;
              width: fit-content;
            }
          `}</style>
        </>
      )}
    </>
  );
}

export default ExcelViewer;
