import React from "react";
import PdfViewerComponent from "./components/renderer/PdfViewer.js";
import ImageViewer from "./components/renderer/ImageViewer.js";
import ExcelViewer from "./components/renderer/ExcelViewer.js";
import "./App.css";
import {
  isImageFile,
  isExcelFile,
  isDocFile,
  isPptFile,
  getFileCategoryType,
} from "./utils/file.js";

const ViewerComponent = () => {
  const fileExtension = window.fileName.split(".").pop().toLowerCase();

  const filename = window.fileName;
  const fileCategory = getFileCategoryType(filename);

  if (fileExtension === "pdf") {
    return (
      <div className="App-viewer">
        <PdfViewerComponent document={`${filename}`} />
      </div>
    );
  } else {
    switch (true) {
      case isImageFile(filename):
        return <ImageViewer filename={`${filename}.${fileCategory}`} />;
      case isExcelFile(filename):
        return <ExcelViewer filename={`${filename}.${fileCategory}`} />;
      default:
        return <div>Unsupported file type: {fileExtension}</div>;
    }
  }
};

function App() {
  return (
    <div className="App">
      <ViewerComponent />
    </div>
  );
}

export default App;
