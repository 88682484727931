import React, { useEffect, useState } from "react";

function ImageViewer({ filename }) {
  const [content, setContent] = useState("");

  useEffect(() => {
    const domain = window.location.hostname;
    setContent(`https://${domain}/${filename}.png`);
  }, [filename]);

  return (
    <div className="image-wrapper">
      {content && <img src={content} alt={filename} />}
      <style jsx>{`
        .image-wrapper {
          max-width: 100%;
          max-height: 90vh;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        img {
          max-width: 100%;
          max-height: 90vh;
          object-fit: contain;
        }
      `}</style>
    </div>
  );
}

export default ImageViewer;
