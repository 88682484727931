
export const imageExtensions = [
  'jpg',
  'jpeg',
  'jpe',
  'jpf',
  'iff',
  'jp2',
  'bmp',
  'bmp2',
  'bmp3',
  'pfm',
  'gif',
  'png',
  'pn8',
  'png24',
  'png32',
  'webp',
  'tif',
  'tiff',
  'svg',
  'svgz',
  'msvgz',
  'psd',
  'xcf',
  'eps',
  'eps2',
  'eps3',
  'epsf',
  'ai',
  'raw',
  'cr2',
  'nef',
  'orf',
  'sr2',
  'crw',
  'dcr',
  'kdc',
  'arw',
  'ptif',
  'pef',
  'raf',
  'x3f',
  'erf',
  'mrw',
  'pct',
  'pict',
  'dds',
  'dng',
  'pex',
  '3fr',
  'ppm',
  'exr',
  'hdr',
  'psb',
  'pbm',
  'pgm',
  'pnm',
  'pam',
  'sgi',
  'xpm',
  'xbm',
  'xwd',
  'pcx',
  'pcc',
  'cur',
  'k25',
  'srf',
  'sct',
  'tga',
  'fit',
  'fits',
  'fts',
  'mng',
  'aai',
  'dcm',
  'dicom'
]

export const excelExtensions = ['xls', 'xlsx', 'xlsm', 'xlsb', 'xltx', 'xltm', 'xlt', 'csv', 'tsv', 'ods', 'ots']

export const docExtensions = [
  'doc',
  'docx',
  'docm',
  'dotx',
  'dotm',
  'dot',
  'rtf',
  'odt',
  'ott',
  'pages',
  'wpd',
  'wps',
  'jtd',
  'jtt',
  'epdf',
  'tex',
  'mml',
  'sdw',
  'sxw'
]

export const ebookExtensions = ['djvu', 'epub', 'mobi', 'azw', 'azw3', 'fb2', 'iba', 'cbr', 'cbz']

export const pptExtensions = ['ppt', 'pptx', 'pptm', 'potx', 'potm', 'pot', 'ppsx', 'ppsm', 'pps', 'ppam', 'pot', 'odp', 'otp', 'sdd', 'sti', 'sx', 'uop', 'sxi']

export const imageMimeTypes = [
  'image/jpeg',
  'image/jp2',
  'image/iff',
  'image/bmp',
  'image/x-portable-float-map',
  'image/gif',
  'image/png',
  'image/webp',
  'image/tiff',
  'image/svg+xml',
  'image/vnd.adobe.photoshop',
  'image/x-xcf',
  'application/postscript',
  'application/illustrator',
  'image/x-dcraw',
  'image/x-portable-pixmap',
  'image/x-exr',
  'image/vnd.radiance',
  'image/x-portable-bitmap',
  'image/x-portable-graymap',
  'image/x-portable-anymap',
  'image/x-portable-arbitrarymap',
  'image/x-sgi',
  'image/x-xpixmap',
  'image/x-xbitmap',
  'image/x-xwindowdump',
  'image/x-pcx',
  'image/x-k25',
  'image/x-srf',
  'image/x-scitex',
  'image/tga',
  'image/x-fit',
  'image/mng',
  'bitmap',
  'image/x-applix-graphics',
  'application/dicom'
]

export const excelMimeTypes = [
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel.sheet.macroEnabled.12',
  'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
  'application/vnd.ms-excel.template.macroEnabled.12',
  'text/csv',
  'text/tab-separated-values',
  'application/vnd.oasis.opendocument.spreadsheet',
  'application/vnd.oasis.opendocument.spreadsheet-template'
]

export const docMimeTypes = [
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-word.document.macroEnabled.12',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
  'application/vnd.ms-word.template.macroEnabled.12',
  'application/rtf',
  'application/vnd.oasis.opendocument.text',
  'application/vnd.oasis.opendocument.text-template',
  'application/vnd.apple.pages',
  'application/vnd.wordperfect',
  'application/vnd.ms-works',
  'application/x-jtd',
  'application/x-jtt',
  'application/pdf',
  'application/x-tex',
  'application/mathml+xml',
  'application/vnd.stardivision.writer',
  'application/vnd.sun.xml.writer'
]

export const ebookMimeTypes = [
  'image/vnd.djvu',
  'application/epub+zip',
  'application/x-mobipocket-ebook',
  'application/vnd.amazon.ebook',
  'application/x-fictionbook+xml',
  'application/x-ibooks+zip',
  'application/x-cbr',
  'application/x-cbz'
]

export const pptMimeTypes = [
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.ms-powerpoint.presentation.macroEnabled.12',
  'application/vnd.openxmlformats-officedocument.presentationml.template',
  'application/vnd.ms-powerpoint.template.macroEnabled.12',
  'application/vnd.ms-powerpoint.template',
  'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
  'application/vnd.ms-powerpoint.slideshow.macroEnabled.12',
  'application/vnd.ms-powerpoint.slideshow',
  'application/vnd.ms-powerpoint.addin.macroEnabled.12',
  'application/vnd.oasis.opendocument.presentation',
  'application/vnd.oasis.opendocument.presentation-template',
  'application/sdd',
  'application/vnd.sun.xml.impress.template',
  'application/vnd.sun.xml.impress',
  'application/vnd.openoffice.presentation'
]

export const isImageFile = (filename, mimeType) => {
  const extension = filename?.split('.')?.pop()
  return imageExtensions.includes(extension?.toLowerCase()) || (mimeType && imageMimeTypes.includes(mimeType))
}

export const isExcelFile = (filename, mimeType) => {
  const extension = filename?.split('.')?.pop()
  return excelExtensions.includes(extension?.toLowerCase()) || (mimeType && excelMimeTypes.includes(mimeType))
}

export const isDocFile = (filename, mimeType) => {
  const extension = filename?.split('.')?.pop()
  return docExtensions.includes(extension?.toLowerCase()) || (mimeType && docMimeTypes.includes(mimeType))
}

export const isPptFile = (filename, mimeType) => {
  const extension = filename?.split('.')?.pop()
  return pptExtensions.includes(extension?.toLowerCase()) || (mimeType && pptMimeTypes.includes(mimeType))
}

export const isPptPdfFile = (filename, mimeType) => {
  const extensions = filename?.split('.').slice(-3)
  for (const extension of extensions) {
    if (pptExtensions.includes(extension?.toLowerCase()) || pptMimeTypes.includes(mimeType)) return true
  }
  return false
}

export const FILE_TYPES = {
  image: 'image',
  excel: 'excel',
  doc: 'doc',
  ppt: 'ppt'
}

export const getFileCategoryType = (filename, mimeType) => {
  switch (true) {
    case isImageFile(filename, mimeType):
      return FILE_TYPES.image
    case isExcelFile(filename, mimeType):
      return FILE_TYPES.excel
    case isDocFile(filename, mimeType):
      return FILE_TYPES.doc
    case isPptFile(filename, mimeType):
      return FILE_TYPES.ppt
    default:
      return null
  }
}